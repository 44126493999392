import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import '../styles/Trending.sass'

const Trending = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  function indexStr (index) {
    return `${index + 1}`.padStart(2, 0)
  }

  return (
    <div className="trending content">
      {posts && posts.map(({ node: post }, index) => (
        <Link key={index} to={post.fields.slug} className="trending__link dim">
          <span className="trending__index">{indexStr(index)}.</span> 
          {post.frontmatter.title}
        </Link>
      ))}
    </div>
  )
}

Trending.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query TrendingQuery {
        allMarkdownRemark(
          sort: { 
            order: [DESC, DESC],
            fields: [frontmatter___trending, frontmatter___date]
          }
          filter: { 
            frontmatter: { 
              templateKey: { eq: "article" },
              trending: { gt: 0 }
          } }
          limit: 10
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Trending data={data} count={count} />}
  />
)

