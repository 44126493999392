import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import BackgroundImage from 'gatsby-background-image'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import '../styles/ArticleSlideshow.sass'

class ArticleSlideshow extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Carousel
        showStatus={false}
        autoPlay
        interval={5000}
        infiniteLoop
        emulateTouch
        showArrows={false}
        showThumbs={false}
      >
        {posts &&
          posts.map(({ node: post }) => (
            <div key={post.id} className="carousel__wrapper">
              {post.frontmatter.featuredimage ? (
                <BackgroundImage
                  fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                />
              ) : null}
              <Link className="legend" to={post.fields.slug}>
                <h2>{post.frontmatter.title}</h2>
                <p>{post.excerpt}</p>
              </Link>
            </div>
          ))}
      </Carousel>
    )
  }
}

ArticleSlideshow.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ArticleSlideshowQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { 
            templateKey: { eq: "article" },
            featuredpost: { eq: true }
          } }
          limit: 10
        ) {
          edges {
            node {
              id
              excerpt(pruneLength: 60, truncate: true)
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ArticleSlideshow data={data} count={count} />}
  />
)
