import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ArticleRoll from '../components/ArticleRoll'
import ArticleSlideshow from '../components/ArticleSlideshow'
import SectionTitle from '../components/SectionTitle'
import Trending from '../components/Trending'

const IndexPage = (props) => {
  const readMoreStyle = {
    display: 'block',
    textAlign: 'center',
    padding: '0.5rem 0',
    marginTop: '1rem',
    borderTop: '1px solid #ccc'
  }
  return (
    <Layout>
      <div>
        <section className="section" style={{background: 'white'}}>
          <div className="container">
            <div className="slideshow">
              <ArticleSlideshow />
            </div>
            <div style={{marginTop: '2rem'}}>
              <div className="columns">
                <div className="column is-9">
                  <SectionTitle>独立发声，推动公民社会发展</SectionTitle>
                  <ArticleRoll allMarkdownRemark={props.data.allMarkdownRemark} />
                  <Link className="primary-link" style={readMoreStyle} to="/article">所有文章 》</Link>
                </div>
                <div className="column is-3">
                  <SectionTitle>热度推荐</SectionTitle>
                  <Trending />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "article" }
        }
      }
      limit: 10
    ) {
      edges {
        node {
          ...articleThumbFields
        }
      }
    }
  }
`
